<template>
    <div class="footer">
        <p>
            <a
                href="https://dnsimple.com/contact"
                class="button"
                target="_blank"
                @click="trackContact"
                >Get in touch</a
            >
            Can't find what you are looking for?
        </p>
    </div>
</template>

<script>
export default {
  props: ['app'],
  methods: {
    trackContact () {
      this.app.track({ event: 'support-contact', q: this.app.q });
    }
  }
};
</script>

<template>
  <div>
    <slot v-if="hasHideCookie" />
    <Modal
      v-else-if="!isBackgroundMode"
      :has-close="false"
      :initial-show="!hasLinkToOpen"
    >
      <template v-slot:header>
        <span class="db text-bold pa2">
          <span v-if="initialLoad && isLoading">Domain Resolution Check</span>
          <span v-else>
            <span class="icon-warning"></span>
            Your changes may not take effect for {{domainName}}
          </span>
        </span>
      </template>

      <template v-slot:button v-if="hasLinkToOpen">
        <a href="javascript:;" @click.prevent="loadChecks">
          <slot />
        </a>
      </template>

      <template v-slot:body="{ closeModal }">
        <div class="pa2">

          <div v-if="initialLoad && isLoading" class="text-centered pv5">
            <div class="spinner">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>

            <p class="pt4">Checking the resolution of {{ domainName }}...</p>
          </div>

          <div v-if="error" class="text-centered pv5">
            {{error}}
          </div>

          <div v-else>
            <template v-for="(check, index) in checks">

              <div :class="`${index !== checks.length - 1 ? 'bb b--moon-gray' : ''} ${index === 0 ? 'pb4' : 'pv4'}`">
                <template v-if="check.key === 'has_expired_registration'">
                  <div class="fr">
                    <a v-if="hasAccess" :href="newDomainRenewalUrl" class="action-link">
                      Renew {{ domainName }}
                    </a>
                  </div>
                  <h3 class="text-bold">Domain {{ domainName }} is expired.</h3>
                  <p class="pb0 mb0">
                    Renew your domain so it can respond to incoming DNS traffic.
                  </p>
                </template>
                <template v-else-if="check.key === 'is_delegated_elsewhere'">
                  <div class="fr">
                    <a href="javascript:;" @click="loadChecks" class="action-link">
                      <span v-if="isLoading">Loading...</span>
                      <span v-else>Refresh</span>
                    </a>
                  </div>
                  <h3 class="text-bold">Domain {{ domainName }} is delegated elsewhere.</h3>
                  <p class="pb0 mb0">
                    Your domain is currently resolving with:
                  </p>
                  <ul class="pb0 mb0">
                    <template v-for="ns in check.data.name_servers">
                      <li>{{ns}}</li>
                    </template>
                  </ul>
                  <div class="alert-sm alert-info mt3">
                    <p>
                      ℹ️ To update your DNS zone, please make the changes through your current DNS provider.
                      If you would like your domain to resolve with the values in your DNSimple account, please follow the instructions in <a href="https://support.dnsimple.com/articles/pointing-domain-to-dnsimple/" target="_blank">our support article</a>.
                    </p>
                  </div>
                </template>
                <template v-else-if="check.key === 'has_inactive_zone'">
                  <h3 class="text-bold">The DNS zone for {{ domainName }} is inactive.</h3>
                  <p class="pb0 mb0">
                    Activate this zone so it can respond to incoming DNS traffic.<br>
                  </p>
                  <div v-if="zoneActivationPrice && hasAccess">
                    <div class="flex">
                      <span class="text-md pt3">$</span>
                      <span class="text-xl ma0 pa0 text-extra-bold">{{ zoneActivationPrice }}</span>
                      <span class="self-center pl1 pt3">/month</span>
                    </div>
                    <a :href="zoneActivationToggleUrl" data-method="post" class="btn btn-default">
                      Activate zone
                    </a>
                  </div>
                  <div v-if="zoneActivationPrice && hasAccess" class="mt2">
                    Your account will be charged when your subscription renews.
                  </div>
                </template>
                <template v-else-if="check.key === 'has_no_registration'">
                  <div class="fr">
                    <a v-if="hasAccess" :href="newDomainRegistrationUrl" class="action-link">
                      Register domain
                    </a>
                  </div>
                  <h3 class="text-bold">Domain {{ domainName }} is not registered.</h3>
                  <p class="pb0 mb0">
                    Register it now so it can respond to incoming DNS traffic.
                  </p>
                </template>
                <template v-else-if="check.key === 'is_delegated_to_other_providers'">
                  <div class="fr">
                    <a href="javascript:;" @click="loadChecks" class="action-link">
                      <span v-if="isLoading">Loading...</span>
                      <span v-else>Refresh</span>
                    </a>
                  </div>
                  <h3 class="text-bold">Domain {{ domainName }} is delegated to additional DNS providers.</h3>
                  <p class="pb0 mb0">
                    Your domain is currently resolving with:
                  </p>
                  <ul class="pb0 mb0">
                    <template v-for="ns in check.data.other_name_servers">
                      <li class="text-bold">{{ ns }}</li>
                    </template>
                    <template v-for="ns in check.data.dnsimple_name_servers">
                      <li>{{ ns }}</li>
                    </template>
                  </ul>
                  <div class="alert-sm alert-info mt3">
                    <p>ℹ️ All changes at DNSimple will need to be replicated to your other DNS providers.</p>
                  </div>
                </template>
              </div>

            </template>

            <p v-if="!hasAccess" class="mt0">
              To resolve <template v-if="checks.length === 1">this issue</template><template v-else>these issues</template>, please reach out to an account admin or domain manager.
            </p>

          </div>

          <div class="flex justify-between">
            <label class="db text-normal">
              <span class="dib mr1">
                <CookieCheckbox
                  :cookie-name="cookieName"
                  :expires-in="1 * 24 * 60 * 60 * 1000 /* one day */"
                />
              </span>
              Don't show this warning for {{ domainName }} for the next 24 hours.
            </label>

            <div>
              <a ref="iUnderstand" v-if="targetUrl" :href="targetUrl" :data-method="targetMethod" class="btn btn-primary">
                I understand
              </a>
              <a ref="iUnderstand" v-else href="javascript:;" @click="closeModal" class="btn btn-primary">
                I understand
              </a>
            </div>
          </div>

        </div>
      </template>

    </modal>
  </div>
</template>

<script>
import ajax from '../../javascript/common/ajax.js';
import Modal from '../modal/component.vue';
import CookieCheckbox from '../cookie-checkbox/component.vue';

export default {
  components: {
    CookieCheckbox,
    Modal
  },

  props: {
    dnsResolutionCheckDomainUrl: {
      type: String,
      default: ''
    },
    newDomainRenewalUrl: {
      type: String,
      default: ''
    },
    zoneActivationToggleUrl: {
      type: String,
      default: ''
    },
    newDomainRegistrationUrl: {
      type: String,
      default: ''
    },
    domainName: {
      type: String,
      default: ''
    },
    cookieName: {
      type: String,
      default: ''
    },
    zoneActivationPrice: {
      type: String,
      default: ''
    },
    targetUrl: {
      type: String,
      default: ''
    },
    targetMethod: {
      type: String,
      default: 'get'
    },
    clicker: {
      type: Function,
      default (el) {
        el.click();
      }
    },
    ajax: {
      type: Function,
      default: ajax
    },
    hasCookie: {
      type: Function,
      default: (cookieName) => {
        return document.cookie.indexOf(cookieName) !== -1;
      }
    },
    hasAccess: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      error: null,
      isBackgroundMode: false,
      initialLoad: true,
      isLoading: false,
      hasHideCookie: this.hasCookie(this.cookieName),
      checks: []
    };
  },

  mounted () {
    if (this.hasHideCookie) return;
    if (this.hasLinkToOpen) return;

    this.loadInBackground();
  },

  computed: {
    hasLinkToOpen () {
      return !!this.$slots.default;
    }
  },

  methods: {
    loadInBackground () {
      this.isBackgroundMode = true;
      this.loadChecks();
    },

    loadChecks () {
      if (this.isLoading)
        return;

      this.error = null;
      this.isLoading = true;

      this.ajax(this.dnsResolutionCheckDomainUrl)
        .then((response) => {
          const checks = response.getAt('response', []);
          const isPass = checks.length === 0;

          this.initialLoad = false;

          if (isPass)
            this.clicker(this.$refs.iUnderstand);
          else {
            this.checks = checks;
            this.isLoading = false;
            this.isBackgroundMode = false;
          }
        })
        .catch((error) => {
          if (typeof error.getMessage === 'function')
            this.error = error.getMessage('We were not able to confirm your domain\'s resolution at this time. It is possible your domain is not set up correctly. Please click the Refresh button below to retry our checks.');
          else
            this.error = error.message;

          this.isLoading = false;
        });
    }
  }
};
</script>

<template>
  <div>
    <div class="l-relative domain-search-input">
      <slot v-if="!query.length" name="no-query" />
      <slot v-else-if="isLoading" name="loading" />
      <slot v-else-if="!suggestions.length" name="no-suggestions" />
      <ul v-else ref="list" class="suggestion-list">
        <li v-for="(suggestion, index) in suggestions">
          <div :class="'suggestion-item ' + (index === selectedIndex ? 'suggestion-selected' : '')" v-on:click="select(suggestion.domain, index)">
            <slot v-if="hasSuggestionSlot" name="suggestion" v-bind:suggestion="suggestion" />
            <template v-else>
              {{ suggestion.domain }}
              <span v-html="suggestion.status"></span>
            </template>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ajax from '../../javascript/common/ajax.js';
import debounce from '../../javascript/utils/debounce.js';

export default {
  props: {
    domainrClientId: {
      type: String,
      default: ''
    },
    suffixesString: {
      type: String,
      default: ''
    },
    query: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      suggestions: [],
      suffixes: JSON.parse(this.suffixesString || '[]'),
      selectedIndex: 0,
      ignoreNextSearch: false,
      isLoading: false
    };
  },
  watch: {
    query: debounce(function (text) {
      // TODO(@sbastn): remove comment once we can use Domainr again
      // this.search(text);
    }, 400, function () {
      this.isLoading = true;
    }),
    selectedIndex (value) {
      if (this.$refs.list) {
        const $li = this.$refs.list.getElementsByClassName('suggestion-item')[value];
        this.scrollToSelection($li);
      }
    }
  },
  computed: {
    hasSuggestionSlot () {
      return typeof this.$slots.suggestion === 'function';
    }
  },
  methods: {
    search (query) {
      if (this.ignoreNextSearch) {
        this.ignoreNextSearch = false;
        return;
      }

      return this.getSuggestions(query).then((suggestions) => {
        suggestions.forEach(suggestion => this.setSuggestionStatus(suggestion));

        this.setSelectedIndex(0);
        this.suggestions = suggestions;
      }).catch(() => {
        this.suggestions = [{
          domain: 'Error searching domains. Try again later :(',
          status: '',
          suffix: {}
        }];
      }).finally(() => {
        this.isLoading = false;
      });
    },
    domainSearchUrl (query) {
      const defaultSuffixes = this.suffixes.map(suffix => suffix.name).join(',');

      return `//api.domainr.com/v2/search?client_id=${this.domainrClientId}&defaults=${defaultSuffixes}&query=${query}`;
    },
    getSuggestions (query) {
      return new Promise((resolve, reject) => {
        ajax(this.domainSearchUrl(query)).then(
          response => {
            const suggestions = response.getAt('results', [])
              .filter(result => result.domain.indexOf(query) !== -1)
              .map(result => ({
                domain: result.domain,
                suffix: this.suffixes.find(suffix => suffix.name === result.zone),
                status: this.formatStatus('...')
              }))
              .filter(result => result.suffix);

            resolve(suggestions);
          },
          reject
        );
      });
    },
    setSuggestionStatus (suggestion) {
      return ajax('//api.domainr.com/v2/status?client_id=' + this.domainrClientId + '&domain=' + suggestion.domain).then(
        response => {
          const status = response.getAt('status.0.summary', 'Not found');
          suggestion.status = this.formatStatus(status);
        },
        () => suggestion.status = 'Not found'
      );
    },
    select (query) {
      this.$emit('select', query);
      this.ignoreNextSearch = true;
      this.resetSuggestions();
    },
    scrollToSelection ($selectedLi) {
      if (this.$refs.list)
        this.$refs.list.scrollTop = (this.selectedIndex + 1) * $selectedLi.offsetHeight - this.$refs.list.offsetHeight;
    },
    resetSuggestions () {
      this.suggestions = [];
    },
    setSelectedIndex (index) {
      this.selectedIndex = index;
    },
    formatStatus (status) {
      switch (status) {
        case 'inactive': // available
          return "<span class='icon-check pull-right suggestion-status suggestion-status-available'>available</span>";
        case 'parked':
        case 'marketed':
        case 'claimed':
        case 'dpml':
        case 'priced':
        case 'expiring':
        case 'deleting':
        case 'transferable':
        case 'registrar':
        case 'active': // taken
          return "<span class='pull-right suggestion-status'>transfer</span>";
        case 'unknown':
        case 'undelegated': // might be available
          return "<span class='pull-right suggestion-status'>check</span>";
        case 'zone':
        case 'tld':
        case 'pending': // TLD exists but not available yet
          return "<span class='pull-right suggestion-status'>not found</span>";
        default:
          return "<span class='pull-right suggestion-status'>" + status + '</span>';
      }
    }
  }
};
</script>

import EventBus from '../common/event-bus';
import AsyncAlert from '../../components/async-alert/component.vue';
import AsyncBlock from '../../components/async-block/component.vue';
import AsyncLink from '../../components/async-link/component.vue';
import Confetti from '../../components/confetti/component.vue';
import ConditionalSectionForNumber from '../../components/conditional-section-for-number/component.vue';
import CookieCheckbox from '../../components/cookie-checkbox/component.vue';
import CopyToClipboard from '../../components/copy-to-clipboard/component.vue';
import Datepicker from '../../components/datepicker/component.vue';
import DomainConnection from '../../components/domain-connection/component.vue';
import DomainInput from '../../components/domain-input/component.vue';
import DomainrSuggestions from '../../components/domainr-suggestions/component.vue';
import DomainNameSelector from '../../components/domain-name-selector/component.vue';
import DomainRedirections from '../../components/domain-redirections/component.vue';
import DnsResolutionCheck from '../../components/dns-resolution-check/component.vue';
import Dropdown from '../../components/dropdown/component.vue';
import EventTrigger from '../../components/event-trigger/component.vue';
import ExpandableTable from '../../components/expandable-table/component.vue';
import FilterSelect from '../../components/filter-select/component.vue';
import FilterSelectContact from '../../components/filter-select-contact/component.vue';
import FilterTag from '../../components/filtertag/component.vue';
import ImportDns from '../../components/import-dns/component.vue';
import InputList from '../../components/input-list/component.vue';
import Modal from '../../components/modal/component.vue';
import RegionalRecords from '../../components/regional-records/component.vue';
import RaaBanner from '../../components/raa-banner/component.vue';
import RenderLive from '../../components/render-live/component.vue';
import ResourceSelector from '../../components/resource-selector/component.vue';
import ShowIfSelected from '../../components/show-if-selected/component.vue';
import SecondaryDnsPrimary from '../../components/secondary-dns-primary/component.vue';
import SecondaryDnsNameServerSet from '../../components/secondary-dns-name-server-set/component.vue';
import TableSearch from '../../components/table-search/component.vue';
import Tab from '../../components/tab/component.vue';
import Tabs from '../../components/tabs/component.vue';
import VueQr from 'vue-qr/src/packages/vue-qr.vue';
import DnsEditor from '../../components/dns-editor/component.vue';
import DnsServicesEditor from '../../components/dns-services-editor/component.vue';
import FlashMsgs from '../../components/flash-msgs/component.vue';
import ContactSalesModal from '../../components/contact-sales-modal/component.vue';

export default {
  'async-alert': AsyncAlert,
  'async-block': AsyncBlock,
  'async-link': AsyncLink,
  confetti: Confetti,
  'copy-to-clipboard': CopyToClipboard,
  'cookie-checkbox': CookieCheckbox,
  'conditional-section-for-number': ConditionalSectionForNumber,
  datepicker: Datepicker,
  'domain-connection': DomainConnection,
  'domain-input': DomainInput,
  'domainr-suggestions': DomainrSuggestions,
  'domain-name-selector': DomainNameSelector,
  'domain-redirections': DomainRedirections,
  'dns-resolution-check': DnsResolutionCheck,
  dropdown: Dropdown,
  'event-trigger': EventTrigger,
  'expandable-table': ExpandableTable,
  'filter-select': FilterSelect,
  'filter-select-contact': FilterSelectContact,
  filtertag: FilterTag,
  'import-dns': ImportDns,
  'input-list': InputList,
  modal: Modal,
  'regional-records': RegionalRecords,
  'raa-banner': RaaBanner,
  'resource-selector': ResourceSelector,
  'render-live': RenderLive,
  'show-if-selected': ShowIfSelected,
  'secondary-dns-primary': SecondaryDnsPrimary,
  'secondary-dns-name-server-set': SecondaryDnsNameServerSet,
  'table-search': TableSearch,
  'event-bus': EventBus,
  tab: Tab,
  tabs: Tabs,
  'vue-qr': VueQr,
  'dns-editor': DnsEditor,
  'dns-services-editor': DnsServicesEditor,
  'flash-msgs': FlashMsgs,
  'contact-sales-modal': ContactSalesModal
};

import ajax from '../../common/ajax.js';
import Processing from '../../legacy/common/processing.js';
import Modal from 'bootstrap/js/dist/modal';
import { MAP_TRIM, MAP_FLAT } from '../../common/mappers.js';
import { FILTER_UNIQ } from '../../common/filters.js';

class DomainListLabels {
  constructor ($, $btn, $modal, $input, $text, $addBtn) {
    this.$ = $;
    this.$btn = $btn;
    this.$modal = $modal;
    this.$input = $input;
    this.$text = $text;
    this.$addBtn = $addBtn;
    this.events();
  }

  events () {
    this.$btn.on('click', () => {
      this.showModal();
    });

    this.$addBtn.on('click', () => {
      this.addLabels(this.$selectedDomains);
    });

    this.$input.on('keyup', (e) => {
      if (e.which === 13)
        this.addLabels(this.$selectedDomains);
    });
  }

  showModal () {
    this.$selectedDomains = this.$('.js-selected-domain:checked');
    this.showDomainList(this.$selectedDomains);
    this.showExistingLabels(this.$selectedDomains);
    this.$modal.show();
  }

  showDomainList ($selectedDomains) {
    this.$text.html('');

    if ($selectedDomains.length > 0) {
      const domains = [...$selectedDomains].map((el) => this.$(el).val());

      this.$text.html('<strong>Selected domains</strong> <br> &bull; ' + domains.join('<br> &bull; '));
    } else
      this.$text.html("<span class='icon-warning text-danger'>You have not selected any domain to update</span>");
  }

  showExistingLabels ($selectedDomains) {
    let allLabels;

    this.$input.val('');

    if ($selectedDomains.length > 0) {
      allLabels = [];

      $selectedDomains.each((index, el) => {
        const labels = '' + this.$(el).data('labels');

        if (labels.length > 0)
          allLabels.push(labels.split(',').map(MAP_TRIM));
      });

      this.$input.val(MAP_FLAT(allLabels).filter(FILTER_UNIQ).join(', '));
    }
  }

  addLabels ($selectedDomains) {
    this.$modal.hide();
    Processing.start(this.$('#domain-listing'));

    Promise.all([...$selectedDomains].map(($domain) => {
      return ajax($domain.getAttribute('data-labels-path'), {
        labels: this.$input.val()
      }, 'put');
    })).then((response) => {
      window.location.reload();
    }).catch(() => {
      alert("Sorry we can't update apply labels at this moment");
      window.location.reload();
    });
  }

  static ready ($) {
    $('.js-domain-list-label-btn').ifdo(() => {
      new DomainListLabels(
        $,
        $('.js-domain-list-label-btn'),
        new Modal($('#domain-list-labels-modal')),
        $('.js-domain-labels-input'),
        $('.js-domains-to-label-text'),
        $('.js-domain-list-labels-modal-btn')
      );
    });
  };
};

export default DomainListLabels;

<template>
  <div>
    <input
      v-model.trim="query"
      v-on:keyup.down="moveSelection(+1)"
      v-on:keyup.up="moveSelection(-1)"
      v-on:keydown.enter.prevent="chooseSelection()"
      v-on:keyup.esc="removeSelection()"
      :placeholder="inputPlaceholder"
      :id="inputId"
      :class="inputClass"
      :name="inputName"
      :required="inputRequired"
      :autofocus="inputAutofocus"
      :autocomplete="inputAutocomplete"
      ref="input"
    >

    <WWWTrimmer
      :query="query"
      v-on:trim="setQuery"
    />

    <DomainrSuggestions
      v-if="domainrClientId"
      v-on:select="setQuery"
      ref="domainr"
      :query="query"
      :domainr-client-id="domainrClientId"
      :suffixes-string="suffixesString"
    />
  </div>
</template>

<script>
import DomainrSuggestions from '../domainr-suggestions/component.vue';
import WWWTrimmer from '../www-trimmer/component.vue';

export default {
  components: {
    DomainrSuggestions,
    WWWTrimmer
  },
  props: {
    domainrClientId: {
      type: String,
      default: ''
    },
    suffixesString: {
      type: String,
      default: ''
    },
    inputPlaceholder: {
      type: String,
      default: ''
    },
    inputId: {
      type: String,
      default: ''
    },
    inputClass: {
      type: String,
      default: ''
    },
    inputName: {
      type: String,
      default: ''
    },
    inputRequired: {
      type: String,
      default: ''
    },
    inputAutofocus: {
      type: String,
      default: ''
    },
    inputAutocomplete: {
      type: String,
      default: ''
    },
    inputValue: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      query: this.inputValue
    };
  },
  methods: {
    moveSelection (delta) {
      if (!this.$refs.domainr)
        return;

      const $domainr = this.$refs.domainr;
      const newIndex = $domainr.selectedIndex + delta;
      const totalSuggestions = $domainr.suggestions.length - 1;
      const limitedNewIndex = Math.max(0, Math.min(totalSuggestions, newIndex));

      $domainr.setSelectedIndex(limitedNewIndex);
    },
    chooseSelection () {
      const $domainr = this.$refs.domainr;
      const $form = this.$refs.input.form;
      const suggestion = $domainr ? $domainr.suggestions[$domainr.selectedIndex] : undefined;

      if (suggestion)
        $domainr.select(suggestion.domain);
      else if ($form) {
        $form.dispatchEvent(new Event('submit')); // Disable the form based on it's `disable_with` attribute
        $form.submit();
      }
    },
    removeSelection () {
      if (!this.$refs.domainr)
        return;

      const $domainr = this.$refs.domainr;

      $domainr.resetSuggestions();
    },
    setQuery (query) {
      this.query = query;
    }
  }
};
</script>

class EditNameServers {
  constructor ($, $btn) {
    this.$ = $;
    this.$btn = $btn;

    this.listenForClick();
  }

  listenForClick () {
    this.$btn.on('click', (e) => {
      const $els = [...document.querySelectorAll(`
        #name_servers_name_server_name_1,
        #name_servers_name_server_name_2,
        #name_servers_name_server_name_3,
        #name_servers_name_server_name_4,
        #name_servers_name_server_name_5,
        #name_servers_name_server_name_6,
        [name="name_servers[][name]"]
      `)];
      const values = $els.map(($el) => $el.value.trim());

      if (this.emptyValues(values))
        if (!confirm('Removing all the name servers will stop your domain from resolving. Your email and website will stop working immediately! Please confirm that this what you want to do.'))
          return e.preventDefault();
    });
  }

  emptyValues (values) {
    for (let i = values.length - 1; i >= 0; i--)
      if (values[i] !== '')
        return false;

    return true;
  }
}

EditNameServers.ready = ($) => {
  $('.js-edit-name-servers').ifdo(function () {
    new EditNameServers($, this);
  });
};

export default EditNameServers;
